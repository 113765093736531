import Blades from '@/components/BladeResolver'
import HeroThreeAnimated from '@/components/Blocks/HeroThreeAnimated'
import { TwitchScheduleBlock } from '@/components/Blocks/TwitchScheduleBlock'
import { useMenuState } from '@/context'
import { Meta } from '@/globals/Meta'
import { Layout } from '@/hoc/Layout'
import { BaseComponentProps, MetadataProps } from '@/types'
import { WrapperProps } from '@/types/blocks'
import { graphql } from 'gatsby'
import { FC, useEffect } from 'react'

export interface PageTemplateProps extends BaseComponentProps {
	data: {
		page: {
			__typename: string
			metadata: MetadataProps
			id: string
			slug: string
			pageName: string
			blades: WrapperProps[]
		}
	}
}

const PageTemplate: FC<PageTemplateProps> = ({ data, location }) => {
	const page = data?.page
	const blades = page?.blades
	const seo = page?.metadata

	const { setMenuOpen } = useMenuState()

	useEffect(() => {
		setMenuOpen(false)

		return () => {
			// ensure closure
			setMenuOpen(false)
		}
	}, [setMenuOpen])

	return (
		<Layout locale={`en`}>
			<Meta {...{ seo }} />
			<Blades {...{ blades }} />
		</Layout>
	)
}

export default PageTemplate

export const query = graphql`
	query getPage($id: String) {
		page: datoCmsPage(id: { eq: $id }) {
			__typename
			id
			slug
			pageName
			metadata {
				description
				title
				twitterCard
				image {
					url
				}
			}
			blades {
				... on DatoCmsHero {
					...Hero
				}
				... on DatoCmsHeroThree {
					__typename
					id
				}
				... on DatoCmsWrapper {
					...Wrapper
				}
			}
		}
	}
`
